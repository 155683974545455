import React from "react";
import { FaAngleDown } from "react-icons/fa";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-yeebet");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("navbar-yeebet2");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-yeebet");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" >
        <Container style={{ maxWidth: "90%" }}>
          <div className="navbar-translate">
            <NavbarBrand href="/index" id="navbar-brand">
              <img className="navBarLogo" src={require("../../assets/img/logo.png").default} />
            </NavbarBrand>

            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>

                {/* <div >
                    <span className="navItemDemoText">Demo</span>
                  </div> */}

                <UncontrolledDropdown nav>
                  <DropdownToggle
                    className="LanguageDiv"
                    onClick={(e) => e.preventDefault()}
                  >
                    <div className="d-flex justify-content-center align-items-center pr-2">
                      <img src={require('../../assets/img/homePage/english.png').default} className="img-fluid languageFlag" />

                      <div className="ml-3">
                        <span className="">English</span>
                      </div>

                      <div className="ml-3">
                        <span className=""><FaAngleDown /></span>
                      </div>

                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem to="#" style={{ color: "#000" }}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={require('../../assets/img/homePage/english.png').default} className="img-fluid languageFlag" />

                        <div className="ml-3">
                          <span className="" style={{color:"#fff"}}>English</span>
                        </div>
                      </div>
                    </DropdownItem>
                    <DropdownItem to="#" style={{ color: "#000" }}>
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={require('../../assets/img/homePage/chinese.png').default} className="img-fluid languageFlag" />
                        <div className="ml-3">
                          <span className="" style={{color:"#fff"}}>中文</span>
                        </div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

              </NavItem>


            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
