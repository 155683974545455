import React from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import "../assets/css/home.scss";
import { Col, Row } from "reactstrap";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Next from "../assets/img/homePage/sliderNext.png";
import Prev from "../assets/img/homePage/sliderPrev.png";

function Index() {

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);


  const [imageIndex, setImageIndex] = React.useState(0);


  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next text-center sliderArrowNext" onClick={onClick} style={{ display: imageIndex === 2 ? "none" : "block", backgroundColor: "transparent" }}>
        <img src={Next} style={{ width: '20px', height: undefined }} />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev text-center sliderArrowPrev" onClick={onClick} style={{ display: imageIndex === 0 ? "none" : "block", backgroundColor: "transparent" }}>
        <img src={Prev} style={{ width: '20px', height: undefined }} />
      </div>
    );
  };


  const settings = {
    dots: false,
    infinite: false,
    lazyLoad: 'ondemand',
    initOnLoad: true,
    nextSlidesToPreload: 1,
    autoplay: false,
    speed: 300,
    slidesToShow: window.innerWidth > 677 ? 3 : 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: 0,
    swipe: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => { setImageIndex(next) },
    // beforeChange: (current, next) => setImageIndex(next),
    // appendDots: dots => {
    //     return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={30} />;
    // }
  };

  return (
    <>
      <IndexNavbar />

      <div className="" id="home">
        <div className="brandsBg position-relative">
          <div className="our-brands position-relative pt-5">
            <Row className="justify-content-center align-items-center pt-5 text-center">
              <Col lg={10} md={11} sm={12} xs={12}>
                <Row className="justify-content-center align-items-center pt-3 text-center">
                  <Col lg={6} md={10} sm={12} xs={12} className="mb-4">
                    <img src={require('../assets/img/homePage/header_phone.png').default} className="img-fluid headerPhoneSize" />
                  </Col>
                  <Col lg={6} md={10} sm={11} xs={11}>
                    <div className="text-center text-lg-left">
                      <span className="headerTitle">Mobile App Download</span> <br />
                      <span className="headerTitle">QR Code</span>
                      <img src={require('../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                      <span className="brandsDesc">Experience With Us Now</span> <br />

                      <Row className="justify-content-start align-items-center pt-5 text-center">
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-4">
                          <a href="/app.apk" style={{ cursor: "pointer" }}>
                            <div className="applicationBtn py-2">
                              <span className="applicationBtnText">
                                Android Download
                              </span>
                            </div>
                          </a>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12} className="mb-4">
                          <a href="/signed.mobileconfig" style={{ cursor: "pointer" }}>
                            <div className="applicationBtn py-2">
                              <span className="applicationBtnText">
                                IOS Download
                              </span>
                            </div>
                          </a>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="sideItem1Pos">
            <img src={require('../assets/img/homePage/sideItem1.png').default} className="img-fluid" />
          </div>

          <div className="sideItem2Pos">
            <img src={require('../assets/img/homePage/sideItem2.png').default} className="img-fluid" />
          </div>
        </div>


        <div className="game-category py-5 position-relative">
          <div className="sideItem3Pos">
            <img src={require('../assets/img/homePage/sideItem3.png').default} className="img-fluid" />
          </div>

          <div className="sideItem4Pos">
            <img src={require('../assets/img/homePage/sideItem4.png').default} className="img-fluid" />
          </div>
          <Row className="justify-content-center align-items-center text-center">
            <Col lg={10} md={10} sm={11} xs={11}>
              <span className="headerTitle">IOS Installation Steps</span> <br />
              <img src={require('../assets/img/home/headerTitle-line.png').default} className="img-fluid" />
              <Row className="justify-content-center align-items-start text-center pt-3">
                <Col lg={9} md={10} sm={11} xs={11} className="slideDescDiv">
                  <span className="brandsDesc" style={{ display: imageIndex === 0 ? "block" : "none" }}>STEP 1: CLICK ON "INSTALL", POPPED UP DOWNLOAD CONFIGURATION, CLICK ON "ALLOW".</span>
                  <span className="brandsDesc" style={{ display: imageIndex === 1 ? "block" : "none" }}>STEP 2: GO TO SETTINGS -> GENERAL -> PROFILE DOWNLOADED, CLICK ON IT</span>
                  <span className="brandsDesc" style={{ display: imageIndex === 2 ? "block" : "none" }}>STEP 3: LOOK FOR THE “INSTALL” BUTTON, PLEASE KEY IN PASSCODE TO UNLOCK PHONE IF NEEDED, CLICK ON “INSTALL” BUTTON AGAIN AND DONE. RETURN TO HOME, AND THERE’S THE NEWLY INSTALLED APP</span>
                </Col>
              </Row>
              <Slider {...settings} className="py-5 text-center" style={{ zIndex: "10", position: "sticky" }}>
                {/* <div className={imageIndex === 0 ? "activeMobileSlide" : "inactiveMobileSlide"} /> */}

                <div className={imageIndex === 0 ? "activeMobileSlide" : imageIndex === 1 ? "inactiveMobileSlide fadeLeft" : "inactiveMobileSlide"} >
                  <img src={require('../assets/img/homePage/slide1Phone.png').default} className="img-fluid mx-auto phoneSlideSize" />
                  <div className="blurSliderLayerRight" />
                  <div className="blurSliderLayerLeft" />
                </div>

                <div className={imageIndex === 1 ? "activeMobileSlide" : imageIndex === 0 ? "inactiveMobileSlide fadeRight" : imageIndex === 2 ? "inactiveMobileSlide fadeLeft" : "inactiveMobileSlide"} >
                  <img src={require('../assets/img/homePage/slide2Phone.png').default} className="img-fluid mx-auto phoneSlideSize" />
                  <div className="blurSliderLayerRight" />
                  <div className="blurSliderLayerLeft" />
                </div>

                <div className={imageIndex === 2 ? "activeMobileSlide" : imageIndex === 1 ? "inactiveMobileSlide fadeRight" : "inactiveMobileSlide"} >
                  <img src={require('../assets/img/homePage/slide3Phone.png').default} className="img-fluid mx-auto phoneSlideSize" />
                  <div className="blurSliderLayerRight" />
                  <div className="blurSliderLayerLeft" />
                </div>

                <div className={imageIndex === 4 ? "activeMobileSlide d-none d-lg-block" : "inactiveMobileSlide d-none d-lg-block"} />
                <div className={imageIndex === 5 ? "activeMobileSlide d-none d-lg-block" : "inactiveMobileSlide d-none d-lg-block"} />
              </Slider>
            </Col>
          </Row>
        </div>
      </div>
      <DarkFooter />
    </>
  );
}

export default Index;
