/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { FaFacebookF, FaLinkedin, FaTwitter, FaTelegram, FaInstagram } from "react-icons/fa";

function DarkFooter() {
  return (
    <footer className="footer">
      <Container style={{ maxWidth: "90%" }}>
        <Row className="justify-content-center align-items-end">
          <Col lg={4} md={4} sm={12} xs={12} className="">
            <div className="text-center text-lg-left">
              <img src={require('../../assets/img/home/footer-logo.png').default} className="img-fluid navBarLogo" />
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={12} className="py-3 py-lg-0">
            <div className="text-center text-lg-right">
              <span className="copywriteText">
                Copyright © 2022 Yeebet Limited. All Right Reserved.
              </span>
            </div>
          </Col>
        </Row>
        <div className="footerBorderBottom mt-4 mb-3" />
      </Container>
    </footer>
  );
}

export default DarkFooter;
